// assets/js/app.js

require("bootstrap");
require("../css/app.scss");
require("../css/app.css");
require("../css/app.admin.css");
//import $ from 'jquery';

require("@fortawesome/fontawesome-free/css/all.min.css");
//require('@fortawesome/fontawesome-free/js/all.js');

var $ = require("jquery");
global.$ = global.jQuery = $;

console.log("Hello Webpack Encore");
